/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h4: "h4",
    pre: "pre",
    code: "code",
    p: "p",
    strong: "strong",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "CLI Reference"), "\n", React.createElement(_components.h2, null, "New And Start Actions"), "\n", React.createElement(_components.h4, null, "Make New Container"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "docker run --name <container-name> -it -e POSTGRES_PASSWORD=<postgres-password> -e POSTGRES_USER=<postgres-user> -p 5432:5432 <image-name>\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "docker run --name pgsql-dev -it -e POSTGRES_PASSWORD=test1234 -e POSTGRES_USER=postgres -p 5432:5432 postgres\n")), "\n", React.createElement(_components.h4, null, "Start Existing Container"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "Usage:  docker start [OPTIONS] CONTAINER [CONTAINER...]\n\nOptions:\n  -a, --attach               Attach STDOUT/STDERR and forward signals\n      --detach-keys string   Override the key sequence for detaching a container\n  -i, --interactive          Attach container's STDIN\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "docker start -ia <cotainer-name> \n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "docker start -ia pgsql-dev\n")), "\n", React.createElement(_components.h3, null, "Bash Terminal in Container"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "docker exec -it <container-name> bash\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "docker exec -it pgsql-dev-2 bash\n")), "\n", React.createElement(_components.h3, null, "Access Postgress From Container CLI"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "psql -h localhost -U postgres\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "psql -h 127.0.0.1 -U postgres\n")), "\n", React.createElement(_components.h4, null, "Trouble shooting"), "\n", React.createElement(_components.p, null, "From within the container check what is running on a given port."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-sh"
  }, "grep -w '5432/tcp' /etc/services\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
